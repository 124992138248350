import * as s from './ArAboutHero.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import React from 'react'
import { TArAboutHeroProps } from './ArAboutHero.types'
import { parseRichText } from 'src/utils/parseRichText'

export const ArAboutHero: React.FC<TArAboutHeroProps> = () => {
  return (
    <ArContainer className={s.container}>
      <h1>We’re building the foundations of modern specialty (re)insurance.</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: parseRichText([
            'At Artificial, we’re empowering top performing brokers and carriers to transform their specialty placement and underwriting strategies with our pioneering technology.',
            { type: 'br' },
            { type: 'br' },
          'Our technology enables our clients to lay the digital foundations for their complex placement or underwriting strategies through our proprietary tooling.',
            { type: 'br' },
            { type: 'br' },
          'Artificial’s uniquely configurable, insurance-specific platform is unlike anything else in the market. Why not get in touch and see what it could do for you?',
          ]),
        }}
      />
    </ArContainer>
  )
}
