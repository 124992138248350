import * as s from './ArAboutNewsLinks.module.scss'
import React from 'react'
import SvgArrowRight from 'src/assets/svg/arrow-right-3.svg'

const links = [
  {
    url: 'https://artificial.io/company/blog/case-study-partnering-with-bms-group-to-drive-efficiency-with-artificials/',
    title: 'Case study: Partnering with BMS Group to drive efficiency with Artificial\'s Contract Builder',
  },
  {
    url: 'https://artificial.io/company/blog/apollo-smart-follow-renews-partnership-with-artificial/',
    title: 'Apollo Smart Follow renews partnership with Artificial',
  },
  {
    url: 'https://artificial.io/company/blog/artificial-appoints-ed-howkins-as-chief-growth-officer/',
    title: 'Artificial appoints Ed Howkins as Chief Growth Officer',
  },
  {
    url: 'https://artificial.io/company/blog/artificial-labs-raises-gbp8m-series-a-round-to-drive-growth-and-continue',
    title: 'Artificial Labs raises £8M Series A+ round to drive growth and continue rapid expansion',
  },
]

export const ArAboutNewsLinks: React.FC = () => {
  return (
    <div className={s.container}>
      <h3>News</h3>
      <ul>
        {links.map((item, i) => (
          <li key={i}>
            <a href={item.url} target="_blank" rel="noreferrer">
              <p>{item.title}</p>
              <SvgArrowRight />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
